import React from 'react'
import { graphql } from 'gatsby'

import DocumentEducationItem from '../components/documentEducationItem'

const DocumentTemplate = ({ data: { document } }) => (
  <DocumentEducationItem document={document} />
)

export const query = graphql`
  query($slug: String!) {
    document: sanityDocumentItem(slug: { current: { eq: $slug } }) {
      title
      id
      body: _rawBody(resolveReferences: { maxDepth: 10 })
      iconImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default DocumentTemplate
